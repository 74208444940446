import "./styles.scss";

import React from "react";

import TrackVisibility from "react-on-screen";
import CountUp from 'react-countup';

const Counter = ({ backgroundColor, fontColor, counterTitle, counterData }) => {

  
    return (
    <section className="counter" style={{background: `${backgroundColor}`, color: `${fontColor}`}}>
      <div className="container-fluid">
        <div className="site-container">
          <div className="counter-wrapper">
            <div className="section-title">{counterTitle}</div>
            <TrackVisibility once offset={300}>
              {({ isVisible }) => (
                <div className="row">
                  {counterData.map((item, index) => {

                    return (
                      <div key={index} className="col-md-3">
                        <div className="counter__item"> 
                          <CountUp start={0} end={isVisible ? `${item.counterNumber}` : 0} duration={3} />
                          <p>{item.counterSubject}</p>
                        </div>
                      </div>  
                    )
                  })}

              </div>
              )}
            </TrackVisibility>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Counter;