import "./styles.scss";

import React from "react";
import { Link } from "gatsby";

const LeftSideText = ({ content }) => {

  const {aboutListBtn, aboutListHeading, aboutListImg, aboutListLink, aboutListMain, aboutListText} = content;

    return (
        <div className="container-fluid">
          <div className="site-container">
            <div className="left-side-text">
              <div className="row">
                <div className="col-md-6 order-2 order-md-1 left-side-text__col-left">
                  <p className="left-side-text__info">{aboutListMain}</p>
                  <h2 className="left-side-text__title">{aboutListHeading}</h2>
                  <div className="left-side-text__text" dangerouslySetInnerHTML={{ __html: aboutListText }} />
                  <Link className="btn-main" to={aboutListLink}>{aboutListBtn}</Link>
                </div>
                <div className="col-md-6 order-1 order-md-2 left-side-text__col-right">
                  <div className="left-side-text__img-wrapper">
                    <img
                      className="homepage-grid__image"
                      src={aboutListImg?.sourceUrl}
                      alt={aboutListImg?.altText}
                      />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
  );
};

export default LeftSideText;